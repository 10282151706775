
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getMembersBySpace } from "@/core/services/api/members";
import { addSpecialAccess } from "@/core/services/api/tokens";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  props: ["groupOptions", "isGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilityOptions: any = reactive([]);
    const userOptions: any = reactive([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datePicker: [
        {
          required: true,
          message: "請選擇時間",
          trigger: "blur",
        },
      ],
      facility: [
        {
          required: true,
          message: "請填選設施",
          trigger: "change",
        },
      ],
      user: [
        {
          required: true,
          message: "請填選使用者",
          trigger: "change",
        },
      ],
    });
    const form = reactive({
      datePicker: [],
      facility: "",
      user: "",
      remark: "",
    });

    const reseatForm = () => {
      Object.assign(form, {
        datePicker: [],
        facility: "",
        user: "",
        remark: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增特殊通行嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                facility: form.facility,
                user: form.user,
                beginTime: getUtcTimeString(form.datePicker[0]),
                endTime: getUtcTimeString(form.datePicker[1]),
                remark: form.remark,
              };
              console.log("request", request);
              await addSpecialAccess(request);
              emit("onAddSpecialAccess");
              await Swal.fire("新增成功!", "", "success");
            }
          });
        }
      });
    };

    const setFacilityOptions = async () => {
      let facility = await getFacilityBySpace(currentSpaceGuid!);
      facilityOptions.splice(0);
      facility.forEach((o) => {
        facilityOptions.push({
          text: o.facility.name,
          value: o.facility.guid,
        });
      });
    };

    const setUserOptions = async () => {
      let members = await getMembersBySpace(currentSpaceGuid!);
      userOptions.splice(0);
      members.forEach((o) => {
        userOptions.push({
          text: `${o.name} (${o.email})`,
          value: o.guid,
        });
      });
    };

    const init = async () => {
      await setFacilityOptions();
      await setUserOptions();
    };
    init();

    onMounted(() => {
      let addSpecialAccessModal: any = document.getElementById(
        "addSpecialAccess-modal"
      );
      addSpecialAccessModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
        formRef.value!.resetFields();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      props,
      facilityOptions,
      userOptions,
    };
  },
});
