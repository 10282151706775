<template>
  <div id="member-addMember">
    <el-form
      @submit.prevent="submit()"
      :model="form"
      :rules="rules"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">起訖時間</span>
        </label>
        <el-form-item prop="datePicker">
          <el-date-picker
            style="width: 100%"
            v-model="form.datePicker"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">權限</span>
        </label>
        <el-form-item prop="facility">
          <el-select
            v-model="form.facility"
            filterable
            placeholder="請選擇設施"
            style="width: 100%"
          >
            <el-option
              v-for="item in facilityOptions"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">使用者</span>
        </label>
        <el-form-item prop="user">
          <el-select
            v-model="form.user"
            filterable
            placeholder="請選擇使用者"
            style="width: 100%"
          >
            <el-option
              v-for="item in userOptions"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>備註</span>
        </label>
        <el-form-item>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="請輸入備註"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getMembersBySpace } from "@/core/services/api/members";
import { addSpecialAccess } from "@/core/services/api/tokens";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  props: ["groupOptions", "isGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilityOptions: any = reactive([]);
    const userOptions: any = reactive([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datePicker: [
        {
          required: true,
          message: "請選擇時間",
          trigger: "blur",
        },
      ],
      facility: [
        {
          required: true,
          message: "請填選設施",
          trigger: "change",
        },
      ],
      user: [
        {
          required: true,
          message: "請填選使用者",
          trigger: "change",
        },
      ],
    });
    const form = reactive({
      datePicker: [],
      facility: "",
      user: "",
      remark: "",
    });

    const reseatForm = () => {
      Object.assign(form, {
        datePicker: [],
        facility: "",
        user: "",
        remark: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增特殊通行嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                facility: form.facility,
                user: form.user,
                beginTime: getUtcTimeString(form.datePicker[0]),
                endTime: getUtcTimeString(form.datePicker[1]),
                remark: form.remark,
              };
              console.log("request", request);
              await addSpecialAccess(request);
              emit("onAddSpecialAccess");
              await Swal.fire("新增成功!", "", "success");
            }
          });
        }
      });
    };

    const setFacilityOptions = async () => {
      let facility = await getFacilityBySpace(currentSpaceGuid!);
      facilityOptions.splice(0);
      facility.forEach((o) => {
        facilityOptions.push({
          text: o.facility.name,
          value: o.facility.guid,
        });
      });
    };

    const setUserOptions = async () => {
      let members = await getMembersBySpace(currentSpaceGuid!);
      userOptions.splice(0);
      members.forEach((o) => {
        userOptions.push({
          text: `${o.name} (${o.email})`,
          value: o.guid,
        });
      });
    };

    const init = async () => {
      await setFacilityOptions();
      await setUserOptions();
    };
    init();

    onMounted(() => {
      let addSpecialAccessModal: any = document.getElementById(
        "addSpecialAccess-modal"
      );
      addSpecialAccessModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
        formRef.value!.resetFields();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      props,
      facilityOptions,
      userOptions,
    };
  },
});
</script>

<style scoped>
</style>

