
import { defineComponent, onMounted, reactive, ref } from "vue";
import { querySpecialAccess } from "@/core/services/api/tokens";
import { getMembersBySpace } from "@/core/services/api/members";
import { releaseSpecialAccess } from "@/core/services/api/tokens";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import AddSpecialAccess from "./components/AddSpecialAccess.vue";
import UpdateRemark from "./components/UpdateRemark.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddSpecialAccess,
    UpdateRemark,
  },
  emits: ["onAddSpecialAccess", "onUpdateRemark"],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const currentSpecialAccess: any = reactive({});
    let addSpecialAccessModal;
    let updateSpecialAccessRemarkModal;
    let facility: any = [];
   
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    const tableHeader: any = reactive([
      { key: "name", name: "使用者" },
      { key: "facility", name: "權限", sortable: true },
      { key: "beginTime", name: "開始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "remark", name: "備註", sortable: true },
      { key: "operate", name: "操作" },
    ]);

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const getSpecialAccess = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      return await querySpecialAccess(request);
    };
    let spaceUsers: any = [];
    const setSpaceUser = async () => {
      spaceUsers = await getMembersBySpace(currentSpaceGuid!);
    };

    const getTargetuser = (target) => {
      let filterUser = spaceUsers.filter((o) => o.guid == target);
      return filterUser[0].name;
    };

    const setFacilityBySpace = async () => {
      facility = await getFacilityBySpace(currentSpaceGuid!);
    };

    const getTargetFacility = (target) => {
      let filterFacility = facility.filter((o) => o.facility.guid == target);
      return filterFacility[0].facility.name;
    };

    const onAddSpecialAccess = async () => {
      await setTableData();
      addSpecialAccessModal.hide();
    };

    const onUpdateRemark = async () => {
      await setTableData();
      updateSpecialAccessRemarkModal.hide();
    };

    const setCurrentSpecialAccess = (item) => {
      Object.assign(currentSpecialAccess, item);
    };

    const deleteSpecialAccess = async () => {
      await Swal.fire({
        title: "您確定要刪除特殊通行嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            tokens: [currentSpecialAccess.guid],
          };
          await releaseSpecialAccess(request);
          await setTableData();
          await Swal.fire("刪除成功!", "", "success");
        }
      });
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let specialAccess: any = await getSpecialAccess();
      tableData.splice(0);
      specialAccess.forEach((o) => {
        tableData.push({
          name: getTargetuser(o.to),
          facility: getTargetFacility(o.facility),
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          remark: o.remark,
          guid: o.guid,
        });
      });
      isLoadind.value = false;
    };

    onMounted(() => {
      addSpecialAccessModal = new Modal(
        document.getElementById("addSpecialAccess-modal")
      );
      updateSpecialAccessRemarkModal = new Modal(
        document.getElementById("updateSpecialAccessRemark-modal")
      );
    });

    const init = async () => {
      await setSpaceUser();
      await setFacilityBySpace();
      await setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      search,
      isLoadind,
      onAddSpecialAccess,
      setCurrentSpecialAccess,
      currentSpecialAccess,
      deleteSpecialAccess,
      onUpdateRemark,
    };
  },
});
