<template>
  <div id="member-addMember">
    <el-form
      @submit.prevent="submit()"
      :model="form"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span>備註</span>
        </label>
        <el-form-item>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="請輸入備註"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { updateTokenRemark } from "@/core/services/api/tokens";

export default defineComponent({
  props: ["currentSpecialAccess"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilityOptions: any = reactive([]);
    const userOptions: any = reactive([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      remark: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要修改特殊通行備註嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                token: props.currentSpecialAccess.guid,
                remark: form.remark,
              };
              await updateTokenRemark(request);
              emit("onUpdateRemark");
              await Swal.fire("修改成功!", "", "success");
            }
          });
        }
      });
    };

    watch(
      props,
      () => {
        form.remark = props.currentSpecialAccess.remark;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      formRef,
      form,
      submit,
      props,
      facilityOptions,
      userOptions,
    };
  },
});
</script>

<style scoped>
</style>

