<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addSpecialAccess-modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-facility="{ row: tableData }">
          {{ tableData.facility }}
        </template>
        <template v-slot:cell-beginTime="{ row: tableData }">
          {{ tableData.beginTime }}
        </template>
        <template v-slot:cell-endTime="{ row: tableData }">
          {{ tableData.endTime }}
        </template>
        <template v-slot:cell-remark="{ row: tableData }">
          {{ tableData.remark }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentSpecialAccess(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#updateSpecialAccessRemark-modal"
                  >修改備註</el-dropdown-item
                >
                <el-dropdown-item @click="deleteSpecialAccess(tableData)"
                  >刪除</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 新增特殊通行modal -->
  <div
    class="modal fade"
    id="addSpecialAccess-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addSpecialAccess-exampleModalLabel">
            新增特殊通行
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddSpecialAccess
            @onAddSpecialAccess="onAddSpecialAccess()"
          ></AddSpecialAccess>
        </div>
      </div>
    </div>
  </div>
  <!-- 修改備註modal -->
  <div
    class="modal fade"
    id="updateSpecialAccessRemark-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="updateSpecialAccessRemark-exampleModalLabel"
          >
            修改備註
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <UpdateRemark
            @onUpdateRemark="onUpdateRemark()"
            :currentSpecialAccess="currentSpecialAccess"
          ></UpdateRemark>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { querySpecialAccess } from "@/core/services/api/tokens";
import { getMembersBySpace } from "@/core/services/api/members";
import { releaseSpecialAccess } from "@/core/services/api/tokens";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import AddSpecialAccess from "./components/AddSpecialAccess.vue";
import UpdateRemark from "./components/UpdateRemark.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddSpecialAccess,
    UpdateRemark,
  },
  emits: ["onAddSpecialAccess", "onUpdateRemark"],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const currentSpecialAccess: any = reactive({});
    let addSpecialAccessModal;
    let updateSpecialAccessRemarkModal;
    let facility: any = [];
   
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    const tableHeader: any = reactive([
      { key: "name", name: "使用者" },
      { key: "facility", name: "權限", sortable: true },
      { key: "beginTime", name: "開始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "remark", name: "備註", sortable: true },
      { key: "operate", name: "操作" },
    ]);

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const getSpecialAccess = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      return await querySpecialAccess(request);
    };
    let spaceUsers: any = [];
    const setSpaceUser = async () => {
      spaceUsers = await getMembersBySpace(currentSpaceGuid!);
    };

    const getTargetuser = (target) => {
      let filterUser = spaceUsers.filter((o) => o.guid == target);
      return filterUser[0].name;
    };

    const setFacilityBySpace = async () => {
      facility = await getFacilityBySpace(currentSpaceGuid!);
    };

    const getTargetFacility = (target) => {
      let filterFacility = facility.filter((o) => o.facility.guid == target);
      return filterFacility[0].facility.name;
    };

    const onAddSpecialAccess = async () => {
      await setTableData();
      addSpecialAccessModal.hide();
    };

    const onUpdateRemark = async () => {
      await setTableData();
      updateSpecialAccessRemarkModal.hide();
    };

    const setCurrentSpecialAccess = (item) => {
      Object.assign(currentSpecialAccess, item);
    };

    const deleteSpecialAccess = async () => {
      await Swal.fire({
        title: "您確定要刪除特殊通行嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            tokens: [currentSpecialAccess.guid],
          };
          await releaseSpecialAccess(request);
          await setTableData();
          await Swal.fire("刪除成功!", "", "success");
        }
      });
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let specialAccess: any = await getSpecialAccess();
      tableData.splice(0);
      specialAccess.forEach((o) => {
        tableData.push({
          name: getTargetuser(o.to),
          facility: getTargetFacility(o.facility),
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          remark: o.remark,
          guid: o.guid,
        });
      });
      isLoadind.value = false;
    };

    onMounted(() => {
      addSpecialAccessModal = new Modal(
        document.getElementById("addSpecialAccess-modal")
      );
      updateSpecialAccessRemarkModal = new Modal(
        document.getElementById("updateSpecialAccessRemark-modal")
      );
    });

    const init = async () => {
      await setSpaceUser();
      await setFacilityBySpace();
      await setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      search,
      isLoadind,
      onAddSpecialAccess,
      setCurrentSpecialAccess,
      currentSpecialAccess,
      deleteSpecialAccess,
      onUpdateRemark,
    };
  },
});
</script>

<style>
</style>